<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="ESG_MANAGEMENT_POINT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="esgManagementPointCd"
            label="지점"
            v-model="searchParam.esgManagementPointCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="ESG_MANAGEMENT_ITEM_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="esgManagementItemTypeCd"
            label="분류"
            v-model="searchParam.esgManagementItemTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="년도"
            type="year"
            default="today"
            name="esgYear"
            v-model="searchParam.esgYear">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            label="담당자"
            :editable="false"
            name="userId"
            v-model="searchParam.userId">
          </c-field>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="ESG(녹색경영) 보고서 목록"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :usePaging="false"
      @table-data-change="tableDataChange"
    >
      <template slot="table-chip">
        <q-btn-group outline >
          <q-chip v-if="this.groupUsers" outline square icon="bookmark">
            {{"지점 담당자: " + this.groupUsers }} 
          </q-chip>
        </q-btn-group>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveReport"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'esgManagement',
  data() {
    return {
      searchParam: {
        esgManagementPointCd: 'EMPC000001',
        esgManagementItemTypeCd: null,
        esgYear: null,
        userId: '',
      },
      grid: {
        merge: [
          { index: 2, colName: 'esgManagementItemTypeCd' },
        ],
        columns: [
          {
            fix: true,
            name: 'esgYear',
            field: 'esgYear',
            label: '년도',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            fix: true,
            name: 'esgManagementPointName',
            field: 'esgManagementPointName',
            label: '지점',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            fix: true,
            name: 'esgManagementItemTypeName',
            field: 'esgManagementItemTypeName',
            label: '분류',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            fix: true,
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '지표명',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            fix: true,
            name: 'gmsIndexName',
            field: 'gmsIndexName',
            label: 'GMS INDEX',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            fix: true,
            name: 'sasbIndexName',
            field: 'sasbIndexName',
            label: 'SASB INDEX',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'quarter01',
            field: 'quarter01',
            label: '1분기',
            style: 'width:90px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'quarter02',
            field: 'quarter02',
            label: '2분기',
            style: 'width:90px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'quarter03',
            field: 'quarter03',
            label: '3분기',
            style: 'width:90px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'quarter04',
            field: 'quarter04',
            label: '4분기',
            style: 'width:90px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month01',
            field: 'month01',
            label: '1월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month02',
            field: 'month02',
            label: '2월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month03',
            field: 'month03',
            label: '3월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month04',
            field: 'month04',
            label: '4월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month05',
            field: 'month05',
            label: '5월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month06',
            field: 'month06',
            label: '6월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month07',
            field: 'month07',
            label: '7월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month08',
            field: 'month08',
            label: '8월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month09',
            field: 'month09',
            label: '9월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month10',
            field: 'month10',
            label: '10월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month11',
            field: 'month11',
            label: '11월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'month12',
            field: 'month12',
            label: '12월',
            style: 'width:100px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'monthTotal',
            field: 'monthTotal',
            label: '누계',
            style: 'width:90px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      groupUsers: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.searchParam.userId = this.$store.getters.user.userId;
      // url setting
      this.listUrl = selectConfig.sai.esg.check.list.url;
      this.userListUrl = selectConfig.sai.esg.user.groupList.url;
      this.saveUrl = transactionConfig.sai.esg.check.save.url;

      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.getUsers();
      },);
    },
    getUsers() {
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.groupUsers = _result.data
      },);
    },
    saveReport() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    tableDataChange(props) {
      let total = 0;
      let quarter1 = 0;
      let quarter2 = 0;
      let quarter3 = 0;
      let quarter4 = 0;
      // m = month를 표현하려는 약자로 사용
      for(let m = 1; m <= 12; m++) {
        total += isNaN(parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])) ? 0 : parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])
      }
      for(let m = 1; m<=3; m++) {
        quarter1 += isNaN(parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])) ? 0 : parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])
      }
      for(let m = 4; m<=6; m++) {
        quarter2 += isNaN(parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])) ? 0 : parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])
      }
      for(let m = 7; m<=9; m++) {
        quarter3 += isNaN(parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])) ? 0 : parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])
      }
      for(let m = 10; m<=12; m++) {
        quarter4 += isNaN(parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])) ? 0 : parseFloat(props.row['month' + (m < 10 ? ('0' + m) : m)])
      }
      if(props.row.esgManagementItemUnitCd == 'EMIUC00007') {
        this.$set(props.row, 'monthTotal', (total/12).toFixed(0))
        this.$set(props.row, 'quarter01', (quarter1/3).toFixed(0))
        this.$set(props.row, 'quarter02', (quarter2/3).toFixed(0))
        this.$set(props.row, 'quarter03', (quarter3/3).toFixed(0))
        this.$set(props.row, 'quarter04', (quarter4/3).toFixed(0))
      } else {
        this.$set(props.row, 'monthTotal', total.toFixed(3))
        this.$set(props.row, 'quarter01', quarter1.toFixed(3))
        this.$set(props.row, 'quarter02', quarter2.toFixed(3))
        this.$set(props.row, 'quarter03', quarter3.toFixed(3))
        this.$set(props.row, 'quarter04', quarter4.toFixed(3))
      }
    },
  },
};
</script>
